import jQuery from 'jquery'

// SETTINGS
import settings from './settings/_bootstrap.custom.scss'

// Generic
import gridt from './generic/_grid.scss'
import reboot from './generic/_reboot.scss'

require('bootstrap/js/src/tooltip')
require('bootstrap/js/src/dropdown')
require('bootstrap/js/src/button')
require('bootstrap/js/src/collapse')
require('bootstrap/js/src/tab')
require('bootstrap/js/src/util')

import down from './components/down'

//styles
import style from './index.scss'

